<template>
    <div class="a-card">
        <router-link :to="{name: 'gallery', params: {id: aid}}">
            <b-card no-body>
                <template #header>
                    <h6 class="mb-0">{{ title }}</h6>
                </template>
                <b-card-img :src="imgUrl" />
                <template #footer>
                    <small class="text-muted">{{ text }}</small>
                </template>
            </b-card>
        </router-link>
    </div>
</template>
<script>

export default {
    name: "AlbumCard",
    props: {
        title: {
            type: String
        },
        imgUrl: {
            type: String
        },
        text: {
            type: String
        },
        aid: {
            type: Number
        },
        count: {
            type: Number
        }
    }
};

</script>

<style scoped>
    .a-card {
        padding-bottom: 0px;
    }
</style>
