<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <!-- Page title -->
                <div class="col-lg-12 text-center">
                    <h2 class="display-3 title">Фотографии духовых оркестров</h2>
                </div>
                <!-- Page title -->

                <!-- Page content -->
                <div class="col-lg-12">
                    <b-card-group columns>
                        <album-card 
                            v-for="item in items" 
                            :key="item.ID"
                            :title="item.AlbumName"
                            :imgUrl="'https://windorchestra.net' + item.CoverImage"
                            :text="item.Description"
                            :aid="item.ID"
                            :count="item.TotalImages"
                        />
                    </b-card-group>
                </div>
                <!-- Page content -->
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios';
import AlbumCard from "./components/AlbumCard";

export default {
    components: {
        AlbumCard
    },
    data () {
        return {
            items: ''
        };
    },
    beforeMount () {
        this.get_albums();
    },
    methods: {
        get_albums() {
            this.setHeaders();

            axios.get(this.$apiUrl + "/get_albums",
                {
                    params: {
                        'active': true
                    }
                }).then(response =>
            {
                this.items = response.data;
            });
        },

        // Set the headers for any API requests
        setHeaders() {
            axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
        }
    }
};
</script>
<style>
    .title {
        padding-bottom: 40px;
    }
</style>